export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		PATIENT: 4
	},
	USER: {
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		}
	},
	MODULES: {
	    USERS: 1,
	    SAN: 2,
	    POSITIONS: 3,
	    PAYMENTS: 4,
	    REPORTS: 5,
	    COMMISSIONS: 6,
	    ACCREDITATIONS: 7,
	    NOTIFICATIONS: 8,
	    STRUCTURE: 9,
	    MODERATORS: 10,
	    COLLECTIONS: 11,
	    REMINDERS: 12
	},
	FREQUENCIES: {
		WEEK: 1,
		MONTH: 2
	},
	DOCUMENT_TYPES: {
		V: 1,
		E: 2,
		G: 3,
		J: 4,
		PASSPORT: 5
	},
	VERIFIED_STATUS: {
	    PENDING: 0,
	    VERIFIED: 1,
	    REJECTED: 2
	},
	STATUS_PAYMENT: {
		PENDING: 1,
		IN_PROGRESS: 2,
		FINISH: 3,
		CANCELLED: 4
	},
	IMPORTANT_POSITIONS: [1,2,3],
	GLOBAL_NOTIFICATION_TYPES: {
		ALL: 1,
		INACTIVE: 2,
		ACTIVE: 3,
		SAN: 4,
		DUE_1: 5,
		DUE_2: 6
	},
	MESSAGE_BOT: {
	    YES: 1,
	    NO: 0
	},
	SAN_TYPES: {
	    PREMIUM: 1,
	    BASIC: 2
	},
	GLOBAL_NOTIFICATION_WAYS: {
		PUSH: 1,
		SMS: 2,
		BOTH: 3
	},
	AUTOACCREDITATIONS: {
	    YES: 1,
	    NO: 0
		},
	INCLUDE_ALT_PHONE: {
		YES: 1,
		NO: 0,
	},
	REMINDER_TYPES: {
	    USERS_WITH_DUES: 1,
	    USERS_WITHOUT_SAN: 2,
	    USERS_WITH_SAN: 3
	},
	REMINDER_CHANNELS: {
		PUSH: 1,
		SMS: 2,
		BOTH: 3
	},
	REMINDER_MODES: {
		DAYS: 1,
		DATE: 2
	},
	ACCREDITED:  {
    	YES: 1,
    	NO: 0
    },
    REMINDER_ACCREDITED: {
    	ALL: 0,
    	YES: 1,
    	NO: 2
    }
}